// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/manual-setup/#extend-sentry-webpack-plugin-options
;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"0bae0a688c7da2c876dff22bf71c21c0a663fbdc"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

import packageJson from '../../package.json';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  enabled: process.env.NEXT_PUBLIC_ENVIRONMENT === 'PRODUCTION',
  normalizeDepth: 10,
  dsn: SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  release: `v${packageJson.version}`,
  attachStacktrace: true,
  sampleRate: process.env.NEXT_PUBLIC_ENVIRONMENT === 'PRODUCTION' ? 0.2 : 1.0,
  tracesSampleRate: process.env.NEXT_PUBLIC_ENVIRONMENT === 'PRODUCTION' ? 0.2 : 1.0,
  denyUrls: [/service\.giosg\.com/],
  integrations: [Sentry.replayIntegration()],
  // Session Replay
  replaysSessionSampleRate: process.env.NEXT_PUBLIC_ENVIRONMENT === 'PRODUCTION' ? 0.1 : 1.0,
  replaysOnErrorSampleRate: 1.0,
});
